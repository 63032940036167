<script setup>
import { useCommon } from "@/store";

const props = defineProps({
  duration: {
    type: Number,
    default: 500,
  },
  items: {
    type: Array,
    default: () => [],
  },
});

const SvgConfirm = resolveComponent("SvgConfirm");
const SvgError = resolveComponent("SvgError");
const SvgDanger = resolveComponent("SvgDanger");

const iconComponents = ref({
  error: SvgError,
  confirm: SvgConfirm,
  danger: SvgDanger,
});

const get_image = (type) => {
  return iconComponents.value[type];
};

const interval = ref(null);
const close = (i) => {
  clearInterval(interval.value);
  useCommon().popNotifications(i);
  interval.value = setInterval(() => {
    close(0);
    if (!props.items.length) clearInterval(interval.value);
  }, props.duration);
};
</script>

<template>
  <div
    class="notification"
    @click.stop.prevent
  >
    <transition-group
      name="fade"
      tag="div"
    >
      <div
        v-for="(item, index) in items"
        :key="`notif${index}`"
      >
        <div class="wrapper">
          <component
            :is="get_image(item.type)"
            class="h-8 w-8 lg:h-12 lg:w-12"
          />
          <p v-text="item.message" />
          <div class="ml-auto mr-0">
            <button
              class="body-1"
              @click.stop.prevent="close(index)"
            >
              <span
                class="hidden lg:block"
                v-text="$t('CLOSE')"
              />
              <svg-close-square class="close-image" />
            </button>
          </div>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/css/functions";
.notification {
  @apply fixed z-100 top-28 left-0 right-0 container;
}

.wrapper {
  @apply sticky flex items-start lg:items-center w-full bg-white rounded-xl mt-3 p-4 lg:px-8 lg:py-5;
  box-shadow: 0 rem(11px) rem(31px) 0 rgba(0, 0, 0, 0.16);

  p {
    @apply text-base leading-7 lg:text-xl lg:font-medium pl-2 lg:pl-4 text-secondary-400;
  }

  button {
    @apply w-6 h-6 lg:w-auto lg:h-auto text-secondary-300;
  }
}

.close-image {
  @apply w-6 h-6 lg:hidden;
}

.fade-enter-active {
  @apply transition-opacity duration-300 ease-in;
}

.fade-leave-active {
  @apply transition-opacity duration-300 ease-out;
}

.fade-enter,
.fade-leave-to {
  @apply opacity-0;
}
</style>
